<template>
  <v-snackbar
    :app="snackbar.app"
    right
    tile
    v-model="snackbar.show"
    :timeout="snackbar.timeout"
    :color="snackbar.error ? 'error' : ''"
  >
    <v-icon v-if="snackbar.success" color="success">mdi-check</v-icon>
    {{ snackbar.text }}
    <template v-slot:action="{ attrs }">
      <v-btn v-if="snackbar.showClose" text v-bind="attrs" @click="snackbar.show = false">Close</v-btn>
    </template>
  </v-snackbar>
</template>

<script>
import bus from '../helpers/bus'
import clone from 'lodash/clone'

const defaultSnackbarOptions = {
  success: false,
  error: false,
  text: '',
  show: false,
  timeout: 3000,
  showClose: false,
}

export default {
  data: () => ({
    snackbar: clone(defaultSnackbarOptions),
  }),
  created() {
    let snackbar = this.snackbar
    bus.$on('message', (payload) => {
      Object.assign(
        snackbar,
        clone(defaultSnackbarOptions),
        payload
      )
      snackbar.show = true
    })
  },
}
</script>