import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'

Vue.config.productionTip = false
setTimeout(async () => {
  const isValid = await store.dispatch('auth/validate')
  if (isValid) {
    store.dispatch('orgs/getOrgs')

    new Vue({
      router,
      store,
      vuetify,
      render: h => h(App),
    }).$mount('#app')
  }
})
