import { HttpClient } from '../helpers/http_client'

const orgsClient = HttpClient.create({
  baseUrl: process.env.VUE_APP_ORGS_API_URL,
})

export default {
  namespaced: true,
  state: {
    orgsMap: null,
    orgs: null,
  },
  mutations: {
    setOrgs(state, orgs) {
      let orgsMap = {}
      orgs.forEach(({ id, name }) => {
        orgsMap[id] = name
      })
      state.orgsMap = orgsMap
      state.orgs = orgs
    },
  },
  getters: {},
  actions: {
    async getOrgs({ commit }) {
      let { data } = await orgsClient.post('/GetOrgs')
      if (data) {
        commit('setOrgs', data.orgs)
      }
    },
  },
}
