import { HttpClient } from '../shared-ui/helpers/http_client'
import { getAccessToken } from '../shared-ui/helpers/sso'
import store from '@/store'

const requestHandler = async request => {
  const accessToken = await getAccessToken()
  if (accessToken) {
    request.headers['Authorization'] = `Bearer ${accessToken}`
  } else {
    store.dispatch('auth/logout', { error: 'Invalid refresh token' })
  }
  return request
}

const orgsApi = HttpClient.create({
  baseUrl: process.env.VUE_APP_ORGS_API_URL,
  requestHandler,
})

function convertToCSV(objArray) {
  let array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray
  let str = ''
  for (let i = 0; i < array.length; i++) {
    let line = ''
    for (let index in array[i]) {
      if (line !== '') line += ','
      let val = array[i][index]
      if (typeof val === 'string' && val.includes(',') && !val.includes('"')) {
        line += '"' + val + '"'
      } else {
        line += val
      }
    }
    str += line + '\r\n'
  }
  return str
}

function exportCSVFile(headers, items, fileTitle) {
  if (headers) {
    items.unshift(headers)
  }
  // Convert Object to JSON
  let jsonObject = JSON.stringify(items)
  let csv = convertToCSV(jsonObject)
  let exportedFilenmae = fileTitle + '.csv' || 'export.csv'
  let blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' })
  if (navigator.msSaveBlob) {
    // IE 10+
    navigator.msSaveBlob(blob, exportedFilenmae)
  } else {
    let link = document.createElement('a')
    if (link.download !== undefined) {
      // feature detection
      // Browsers that support HTML5 download attribute
      let url = URL.createObjectURL(blob)
      link.setAttribute('href', url)
      link.setAttribute('download', exportedFilenmae)
      link.style.visibility = 'hidden'
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    }
  }
}

export default {
  namespaced: true,
  state: {
    patientBillingReport: [],
    novoBillingReport: null,
  },
  mutations: {
    setPatientBillingReport(state, reports) {
      state.patientBillingReport = reports
    },
    setNovoBillingReport(state, reports) {
      state.novoBillingReport = reports
    },
  },
  actions: {
    async getBillingReport({ commit }, { orgId, startDate, endDate }) {
      let { data } = await orgsApi.post('/GetBillingReport', {
        id: orgId,
        startDate: startDate,
        endDate: endDate,
        min99454DayRequirement: 1,
      })
      let patientsWithData = data.patientReports.filter(item => {
        item.totalReadingDays = 0
        item.periods.forEach(period => {
          item.totalReadingDays += period.daysWithData.length
        })
        if (
          // item.periods.length ||
          item.qualification99453 ||
          // item.qualifications99454.length ||
          item.qualifications99457.length ||
          item.qualifications99458.length ||
          item.totalReadingDays > 0
        ) {
          return true
        }
        return false
      })
      commit('setPatientBillingReport', patientsWithData)
    },
    async getInvoice({ commit }, { startDate, endDate }) {
      let { data } = await orgsApi.post('/GetInvoices', {
        startDate,
        endDate,
      })
      commit('setNovoBillingReport', data.enrolledPatients)
    },
    downloadCSV(_, { headers, items, title }) {
      exportCSVFile(headers, items, title)
    },
  },
}
