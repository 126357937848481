<template>
  <v-avatar
    :color="color"
    :width="size"
    :height="size"
    :min-width="size"
    class="avatar"
    @click="$emit('click')"
  >
    <v-img v-if="image" :width="size" :height="size" :max-width="size" :src="image" />
    <span v-else :class="font" :style="fontStyle">{{name | initials}}</span>
  </v-avatar>
</template>

<style scoped>
.avatar {
  border: 2px solid white;
}
</style>

<script>
export default {
  props: {
    color: {
      type: String,
      default: 'primary',
    },
    image: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    size: {
      type: Number,
      default: 32,
    },
    font: {
      type: String,
      default: 'body-1 white--text',
    },
    fontStyle: {
      type: String,
      default: '',
    },
  },
  filters: {
    initials(val = '') {
      let names = val.split(' ')
      if (names.length > 0) {
        return (
          names[0].substring(0, 1).toUpperCase() +
          names[names.length - 1].substring(0, 1).toUpperCase()
        )
      }
      return names[0].substring(0, 1).toUpperCase()
    },
  },
}
</script>