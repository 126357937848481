<template>
  <div>
    <v-dialog persistent v-model="showWarn" max-width="500" scrollable>
      <v-card>
        <v-card-title class="title">
          Still Here?
          <v-spacer></v-spacer>
          <v-progress-circular
            style="font-size: 13px;"
            class="font-weight-bold ml-1 mr-n3 mt-n2"
            :rotate="-90"
            :size="32"
            :width="4"
            :value="countdownPercent"
            color="primary"
          >{{countdown}}</v-progress-circular>
        </v-card-title>

        <v-card-text>
          <div class="py-2">
            Your current session will expire shortly. In order to keep Protected Health Information safe, we periodically check to
            see if you're still here. You can stay logged in by clicking below.
          </div>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn outlined color="primary" @click="logout">Log Out</v-btn>
          <v-spacer></v-spacer>
          <v-btn depressed dark color="primary" @click="hideWarningMessage">Stay Logged In</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import bus from '@/shared-ui/helpers/bus'

export default {
  name: 'AutoLogout',
  props: {
    warnTimeout: {
      type: Number,
      default: 15 * 60 * 1000, // 15 minutes
    },
    defaultCountdown: {
      type: Number,
      default: 60, // 60 seconds
    },
  },
  data: function () {
    return {
      events: ['click', 'mousemove', 'mousedown', 'scroll', 'keypress', 'load'],
      resetTimer: null,
      checkTimer: null,
      showWarn: false,
      countdown: 0,
      countdownPercent: 0,
    }
  },
  methods: {
    ...mapActions('auth', ['logout', 'updateLastActivity', 'getLastActivity', 'clearLastActivity']),
    hideWarningMessage() {
      this.updateLastActivity()
      this.showWarn = false
    },
    showWarningMessage() {
      this.showWarn = true
    },
    startCheckTimer() {
      this.stopCheckTimer()
      this.checkTimer = setInterval(async () => {
        let lastActivity = await this.getLastActivity()
        if (!lastActivity) {
          this.logout()
        } else if (Date.now() - lastActivity >= this.warnTimeout) {
          this.showWarningMessage()
          let secondsLeft = Math.ceil(
            (Date.now() -
              lastActivity -
              this.warnTimeout -
              this.defaultCountdown) /
              1000
          )
          this.countdown = this.defaultCountdown - secondsLeft
          this.countdownPercent =
            100 - (this.countdown / this.defaultCountdown) * 100
          if (this.countdown <= 0) {
            this.logout({ auto: 1 })
          }
        } else if (this.showWarn) {
          this.hideWarningMessage()
        }
      }, 1000)
    },
    stopCheckTimer() {
      clearInterval(this.checkTimer)
    },
    updateActivity() {
      if (!this.showWarn) {
        this.updateLastActivity()
      }
    },
    startListeners() {
      this.events.forEach(function (event) {
        window.addEventListener(event, this.updateActivity)
      }, this)
      this.startCheckTimer()
    },
    stopListeners() {
      this.hideWarningMessage = true
      this.events.forEach(function (event) {
        window.removeEventListener(event, this.updateActivity)
      }, this)
      this.stopCheckTimer()
    },
  },
  created() {
    // listen for logout, stop all listeners
    bus.$on('logout', () => {
      this.stopListeners()
      // remove last active cookie
      this.clearLastActivity()
    })
    this.updateLastActivity()
  },
  mounted() {
    this.startListeners()
  },
  destroyed() {
    this.stopListeners()
  },
}
</script>