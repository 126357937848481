import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    redirect: { name: 'Reports' },
    component: Home,
  },
  {
    path: '/billing',
    name: 'Billing',
    redirect: { name: 'Reports' },
  },
  {
    path: '/reports',
    name: 'Reports',
    component: () =>
      import(/* webpackChunkName: "Reports" */ '@/views/Reports.vue'),
  },
  {
    path: '/invoices',
    name: 'Invoices',
    component: () =>
      import(/* webpackChunkName: "invoices" */ '@/views/Invoices.vue'),
  },
  {
    path: '/settings',
    name: 'Settings',
    redirect: { name: 'Reports' },
    component: () =>
      import(/* webpackChunkName: "settings" */ '@/views/Settings.vue'),
  },
  {
    path: '/staff',
    name: 'Staff',
    redirect: { name: 'Reports' },
    component: () =>
      import(/* webpackChunkName: "staff" */ '@/views/Staff.vue'),
  },
  {
    path: '*',
    redirect: '/',
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

export default router
