<template>
  <v-app>
    <v-app-bar ref="toolbar" app dense flat color="primary" v-mutate="onMutate">
      <Logo class="clickable" @click.native="goToApp" />
      <v-spacer></v-spacer>
      <div class="font-weight-medium white--text">
        <v-select
              :items="userOrgs"
              label="Select organization..."
              item-text="name"
              item-value="id"
              v-model="selectedOrgId"
              flat
              dense
              solo
              hide-details
              color="grey darken-2"
              background-color="grey lighten-4"
              style="font-weight: bold;max-width: 300px"
            >
          </v-select>
      </div>
      <v-spacer></v-spacer>
      <app-switcher color="white"></app-switcher>
      <ProfileMenu color="white" font="primary--text subtitle-2 font-weight-bold" />
      <!-- <template v-slot:extension>
      <v-tabs v-model="tab" dark show-arrows>-->
      <!-- <v-tab to="/">Dashboard</v-tab> -->
      <!-- <v-tab :to="{name:'Billing'}">Billing</v-tab> -->
      <!-- <v-tab :to="{name:'Invoices'}">Invoices</v-tab> -->
      <!-- <v-tab :to="{name:'Staff'}">Staff</v-tab> -->
      <!-- <v-tab :to="{name:'Settings'}">Settings</v-tab> -->
      <!-- </v-tabs>
      </template>-->
    </v-app-bar>

    <v-main>
      <router-view :key="$route.fullPath" />
    </v-main>

    <!-- Welcome Dialog -->
    <!-- <welcome-dialog></welcome-dialog> -->

    <!-- Dev Mode Indicator -->
    <dev-mode-indicator v-if="isDevMode"></dev-mode-indicator>

    <!-- Auto Logout -->
    <auto-logout></auto-logout>

    <!-- Snackbar Message -->
    <snackbar-message></snackbar-message>

    <!-- Snackbar Refresh -->
    <snackbar-refresh></snackbar-refresh>
  </v-app>
</template>

<style lang="scss">
.org-name {
  border: 2px solid white !important;
}

.clickable {
  cursor: pointer;
}

.v-btn,
.v-tab {
  text-transform: capitalize !important;
}

.link {
  text-decoration: none;
}
</style>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import Logo from '@/shared-ui/components/Logo'
import AutoLogout from '@/shared-ui/components/AutoLogout'
import ProfileMenu from '@/shared-ui/components/ProfileMenu'
import AppSwitcher from '@/shared-ui/components/AppSwitcher'
import SnackbarMessage from '@/shared-ui/components/SnackbarMessage'
import SnackbarRefresh from '@/shared-ui/components/SnackbarRefresh'
import DevModeIndicator from './shared-ui/components/DevModeIndicator'

export default {
  name: 'App',

  components: {
    AppSwitcher,
    AutoLogout,
    DevModeIndicator,
    ProfileMenu,
    Logo,
    SnackbarMessage,
    SnackbarRefresh,
  },

  data: () => ({
    drawer: false,
    tab: 1,
    showRightDrawer: true,
    selectedOrgId: null,
  }),

  computed: {
    ...mapState(['activeOrg']),
    ...mapState('auth', ['authUser']),
    ...mapState('orgs', ['orgs']),
    theme() {
      return this.$vuetify.theme.dark ? 'dark' : 'light'
    },
    isDevMode() {
      return process.env.VUE_APP_ORGS_API_URL !== 'https://api.orgs.novorpm.com'
    },
    version() {
      return process.env.VUE_APP_VERSION
    },
    userOrgs() {
      if (!this.authUser || !this.orgs) return []
      const userOrgIds = new Set()
      this.authUser.orgs.map(org => userOrgIds.add(org.orgId))
      return this.orgs.filter(org => userOrgIds.has(org.id)).sort((a, b) => a.name.localeCompare(b.name) )
    },
    userOrgsDict() {
      return this.userOrgs.reduce((d, org) => {
        d[org.id] = org
        return d
      }, {})
    },
  },

  methods: {
    ...mapMutations(['setActiveOrg']),
    ...mapActions('auth', ['logout']),
    goToApp() {
      location.href = '/'
    },

    onMutate() {
      let height = 0
      const toolbar = this.$refs.toolbar
      if (toolbar) height = `${toolbar.$el.offsetHeight}px`
      document.documentElement.style.setProperty('--toolbarHeight', height)
    }
  },

  watch: {
    selectedOrgId() {
      if (!this.userOrgsDict || !this.selectedOrgId) {
        this.setActiveOrg(null)
        return
      }
      this.setActiveOrg(this.userOrgsDict[this.selectedOrgId])
    },
    userOrgs() {
      if (!this.userOrgs) this.selectedOrgId = null
      const qOrgId = this.$route.query.orgId
      this.selectedOrgId = qOrgId ? qOrgId : this.userOrgs[0].id
    }
  },

  created() {
    this.$vuetify.theme.dark = false
  },

  mounted() {
    this.onMutate()
  },
}
</script>
