import { setCookie, getCookie } from './cookie'
import { HttpClient } from './http_client'

const REFRESH_TOKEN_ID = 'rtok'
const ACCESS_TOKEN_ID = 'atok'

const ssoClient = HttpClient.create({
  baseUrl: 'https://api.sso.novorpm.com',
})

export const setBaseUrl = url => {
  ssoClient.config.baseUrl = url
}

export const parseJWT = token => {
  if (token) {
    const base64UrlSlice = token.split('.')
    if (base64UrlSlice.length) {
      const base64Url = base64UrlSlice[1]
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
      const jsonPayload = decodeURIComponent(
        atob(base64)
          .split('')
          .map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
          })
          .join('')
      )
      return JSON.parse(jsonPayload)
    }
  }
}

export const setRefreshToken = (refreshToken, expiration) => {
  setCookie(REFRESH_TOKEN_ID, refreshToken, expiration * 1000)
}

export const getRefreshToken = async (
  firebaseIdToken,
  storeInCookie = true
) => {
  let token = getCookie(REFRESH_TOKEN_ID)
  if (token) {
    return token
  }
  if (firebaseIdToken) {
    const { data } = await ssoClient.post('/GetRefreshToken', {
      firebaseIdToken,
    })
    if (storeInCookie) {
      setRefreshToken(data.token, data.expires)
    }
    return data.token
  }
}

const setAccessToken = (accessToken, expiration) => {
  setCookie(ACCESS_TOKEN_ID, accessToken, expiration * 1000)
}

export const getAccessToken = async () => {
  let token = getCookie(ACCESS_TOKEN_ID)
  if (token) {
    return token
  }
  const refreshToken = await getRefreshToken()
  if (refreshToken) {
    const { data } = await ssoClient.post('/GetAccessToken', {
      refreshToken,
    })
    setAccessToken(data.token, data.expires)
    return data.token
  }
}

export const getUserProfile = async ({ userId, userRefId }) => {
  const { data } = await ssoClient.post('/GetUserProfile', {
    userId,
    userRefId,
  })
  return data.user
}

export const clearTokens = () => {
  setCookie(REFRESH_TOKEN_ID, '', Date.now())
  setCookie(ACCESS_TOKEN_ID, '', Date.now())
}
