export class HttpClient {
  static _instance
  static defaultHeaders = { 'Content-Type': 'application/json' }

  isJson(str) {
    try {
      JSON.parse(str)
    } catch (e) {
      return false
    }
    return true
  }

  config = {
    baseUrl: '',
    urlHandler: (baseUrl, uri, query) => {
      var url = `${baseUrl}${uri}`
      if (uri.includes('://')) {
        url = uri
      }
      const queryParams = this._queryParamsToString(query)
      if (queryParams) {
        return `${url}?${queryParams}`
      }
      return url
    },
    beforeRequestHandler: async request => {
      return request
    },
    requestHandler: async request => {
      request = await this.config.beforeRequestHandler(request)
      return request
    },
    responseHandler: response => {
      return response.text().then(text => {
        let data = text
        if (this.isJson(text)) {
          data = JSON.parse(text)
        }

        if (!response.ok) {
          const error = (data && data.message) || response.statusText
          return Promise.reject(error)
        }
        return this.config.afterResponseHandler({ data })
      })
    },
    afterResponseHandler: result => {
      return result
    },
  }

  _queryParamsToString(params) {
    const urlParams = new URLSearchParams()
    for (let k in params) {
      urlParams.append(k, params[k])
    }
    return urlParams.toString()
  }

  constructor(config) {
    Object.assign(this.config, config || {})
  }

  async get(uri, query) {
    const url = this.config.urlHandler(this.config.baseUrl, uri, query)
    const request = await this.config.requestHandler({
      method: 'GET',
      mode: 'cors',
      cache: 'no-cache',
      headers: {
        ...this.defaultHeaders,
      },
    })
    return fetch(url, request).then(this.config.responseHandler)
  }

  async post(uri, body, query) {
    const url = this.config.urlHandler(this.config.baseUrl, uri, query)
    const request = await this.config.requestHandler({
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      headers: {
        ...HttpClient.defaultHeaders,
      },
      body: JSON.stringify(body),
    })
    return fetch(url, request).then(this.config.responseHandler)
  }

  async put(uri, body, query) {
    const url = this.config.urlHandler(this.config.baseUrl, uri, query)
    const request = await this.config.requestHandler({
      method: 'PUT',
      mode: 'cors',
      cache: 'no-cache',
      headers: {
        ...this.defaultHeaders,
      },
      body: JSON.stringify(body),
    })
    return fetch(url, request).then(this.config.responseHandler)
  }

  async patch(uri, body, query) {
    const url = this.config.urlHandler(this.config.baseUrl, uri, query)
    const request = await this.config.requestHandler({
      method: 'PATCH',
      mode: 'cors',
      cache: 'no-cache',
      headers: {
        ...this.defaultHeaders,
      },
      body: JSON.stringify(body),
    })
    return fetch(url, request).then(this.config.responseHandler)
  }

  async delete(uri, query) {
    const url = this.config.urlHandler(this.config.baseUrl, uri, query)
    const request = await this.config.requestHandler({
      method: 'DELETE',
      mode: 'cors',
      cache: 'no-cache',
      headers: {
        ...this.defaultHeaders,
      },
    })
    return fetch(url, request).then(this.config.responseHandler)
  }

  static instance(config) {
    if (!this._instance) {
      this._instance = new HttpClient(config)
    }
    return this._instance
  }

  static create(config) {
    return new HttpClient(config)
  }
}
