<template>
  <div>
    <div v-if="size === 'large'" class="d-flex align-center">
      <img height="60" :src="imgUrl" class="mr-2" />
    </div>
    <div v-else-if="size === 'medium'" class="d-flex align-center">
      <img height="48" :src="imgUrl" class="mr-2" />
    </div>
    <div v-else-if="size === 'small'" class="d-flex align-center">
      <img height="32" :src="imgUrl" class="mr-2" />
    </div>
    <div v-else class="d-flex align-center">
      <img height="24" :src="imgUrl" class="mr-2" />
    </div>
  </div>
</template>

<script>
import DarkLogo from './img/header_logo.svg'

export default {
  props: ["size", "dark"],
  computed: {
    imgUrl() {
      if (this.dark) {
        return DarkLogo
      }
      return "https://firebasestorage.googleapis.com/v0/b/onechart-monitor-prod.appspot.com/o/UX%2FheaderLogo.svg?alt=media&token=1367e2f7-98f9-41f3-abf4-7e440f9f655f"
    }
  }
}
</script>