<template>
  <v-snackbar app top centered :value="updateExists" :timeout="-1" color="snackbarColor">
    <!-- <v-icon left>mdi-information-outline</v-icon> -->
    <span class="font-weight-medium">There is a new version available.</span>
    <template v-slot:action="{ attrs }">
      <v-btn depressed small color="snackbarButtonColor" v-bind="attrs" class="text-capitalize mr-2" @click="refreshApp">Click To Refresh</v-btn>
    </template>
  </v-snackbar>
</template>

<style scoped>
.color {
  background: #565a81;
}
</style>

<script>
import '../scripts/registerServiceWorker'

export default {
  data() {
    return {
      // refresh variables
      refreshing: false,
      registration: null,
      updateExists: false,
    }
  },

  created() {
    // Listen for our custom event from the SW registration
    // document.addEventListener('swUpdated', this.updateAvailable, { once: true })
    document.addEventListener('swUpdated', this.updateAvailable)

    if (navigator.serviceWorker) {
      // Prevent multiple refreshes
      navigator.serviceWorker.addEventListener('controllerchange', () => {
        if (this.refreshing) return
        this.refreshing = true
        // Here the actual reload of the page occurs
        window.location.reload()
      })
    }
  },

  methods: {
    // Store the SW registration so we can send it a message
    // We use `updateExists` to control whatever alert, toast, dialog, etc we want to use
    // To alert the user there is an update they need to refresh for
    updateAvailable(event) {
      this.registration = event.detail
      this.updateExists = true
    },

    // Called when the user accepts the update
    refreshApp() {
      this.closeRefresh()
      // Make sure we only send a 'skip waiting' message if the SW is waiting
      if (!this.registration || !this.registration.waiting) return
      // send message to SW to skip the waiting and activate the new SW
      this.registration.waiting.postMessage('skipWaiting')
      // this.registration.waiting.postMessage({ type: 'SKIP_WAITING' })
    },

    closeRefresh() {
      this.updateExists = false
    },
  },
}
</script>