<template>
  <div class="text-center">
    <v-menu v-model="menu" offset-y>
      <template v-slot:activator="{ on }">
        <v-btn depressed icon color="transparent" v-on="on" class="body-2">
          <UserAvatar :color="color" :font="font" :image="authUser.image" :name="name" />
        </v-btn>
      </template>

      <v-card width="300">
        <v-list dense class="pa-0">
          <v-list-item dense class="grey lighten-3">
            <v-row class="d-flex subtitle-2" justify="center">{{orgName}}</v-row>
          </v-list-item>
          <v-divider></v-divider>
          <div dense class="d-flex flex-column align-center pt-6 pb-5">
            <UserAvatar
              :image="authUser.photoUrl"
              :name="name"
              :size="80"
              color="#4385f4"
              font="white--text"
              fontStyle="font-size: 36px"
            />
            <div class="pt-2" style="font-size: 18px">{{authUser.name}}</div>
            <div class="subtitle-2 font-weight-light">{{authUser.email}}</div>
            <div class="d-flex font-weight-medium grey--text pt-2" style="font-size:12px" justify="center">v.{{version}}</div>
          </div>
          <v-divider />
          <v-list-item @click="logout">
            <v-list-item-title class="font-weight-regular d-flex align-center justify-center">
              <v-icon left class="ml-n2 mr-2">mdi-logout-variant</v-icon>Sign out
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import UserAvatar from './UserAvatar'

export default {
  props: {
    color: {
      default: 'primary',
    },
    font: {
      default: 'body-1 white--text',
    },
  },
  components: {
    UserAvatar,
  },
  filters: {
    first(val = '') {
      if (val) {
        return val.substring(0, 1).toUpperCase()
      }
      return val
    },
  },
  data: () => ({
    fav: true,
    menu: false,
    message: false,
    hints: true,
  }),
  computed: {
    ...mapState('auth', ['authUser', 'claims']),
    ...mapState('orgs', ['orgs']),
    name() {
      return this.authUser.displayName || this.authUser.email
    },
    version() {
      return process.env.VUE_APP_VERSION
    },
    orgName() {
      if (this.authUser && this.orgs) {
        for (let i = 0; i < this.orgs.length; i++) {
          const element = this.orgs[i]
          if (this.authUser.orgId === element.id) {
            return element.name
          }
        }
      }
      return ''
    },
  },
  methods: {
    ...mapActions('auth', ['logout']),
  },
}
</script>