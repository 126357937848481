import Vue from 'vue'
import Vuex from 'vuex'
import auth from '../shared-ui/store/auth'
import orgs from '../shared-ui/store/orgs'
import report from './report'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    auth,
    orgs,
    report,
  },
  state: {
    activeOrg: null,
    ready: false,
    title: '',
    devMode: localStorage.getItem('dev_mode') === 'true',
  },
  mutations: {
    setActiveOrg(state, activeOrg) {
      state.activeOrg = activeOrg
    },
    setTitle(state, title) {
      state.title = title
      document.title = process.env.VUE_APP_TITLE + ' - ' + title
    },
    setReady(state, ready = false) {
      state.ready = ready
    },
    setDevMode(state, mode) {
      state.devMode = mode
      localStorage.setItem('dev_mode', mode)
    },
  },
  actions: {
    toggleDevMode({ state, commit }) {
      commit('setDevMode', !state.devMode)
    },
  },
})
